import React from 'react';
import { useIntl } from "gatsby-plugin-intl";

import { AboutUs } from '../components/about-us/AboutUs';
import SEO from '../components/seo/SEO';

export default () => {
  const intl = useIntl();

  return (
    <>
      <SEO title={intl.formatMessage({id: 'abouUsSeoTitle'})} />
      <AboutUs />
    </>
  );
}
