import React from 'react';

import Sygnet from '../../assets/svg/sygnet.svg';

import s from './Step.scss';


export const Step = ({ bigCircle, text }) => {
  const renderStep = (bigCircle) => {
    if (bigCircle) {
      return (
        <>
          <div className={s(s.step__bigCircle)}>
            <Sygnet className={s.step__sygnet}/>
          </div>
          <p className={s(s.step__textBlock, s.step__bigCircleTxt)}>{text}</p>
        </>
      );
    }

    return (
      <>
        <div className={s.step__smallCircle}/>
        <p className={s.step__textBlock}>{text}</p>
      </>
    );
  };

  return renderStep(bigCircle)
};
