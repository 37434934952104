import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import { Step } from 'components/step/Step';

import Arrow from '../../assets/svg/arrow.svg';

import s from './Timeline.scss';

import { gsap, Power3, TimelineLite } from 'gsap';
import { Draggable } from '../../utils/Draggable';
import { InertiaPlugin } from '../../utils/InertiaPlugin';
import { useAppStateContext } from '../../context/appContext';

export const Timeline = ({ onCursor }) => {

  const [isArrowLeftActive, setArrowLeftActive] = useState(false);
  const [isArrowRightActive, setArrowRightActive] = useState(true);
  const [xTranslate, setXTranslate] = useState<number>(0);

  const wrapperRef = useRef(null);

  const tl = new TimelineLite();

  const intl = useIntl();

  const firstSliderBlock =
    intl.formatMessage({ id: 'firstSliderBlock1' }) +
    intl.formatMessage({ id: 'firstSliderBlock2' });

  const secondSliderBlock = intl.formatMessage({ id: 'secondSliderBlock' });

  const thirdSliderBlock = intl.formatMessage({ id: 'thirdSliderBlock' });

  const fourthSliderBlock =
    intl.formatMessage({ id: 'fourthSliderBlock1' }) +
    intl.formatMessage({ id: 'fourthSliderBlock2' });

  const fifthSliderBlock = intl.formatMessage({ id: 'fifthSliderBlock' });

  const steps = [
    firstSliderBlock,
    secondSliderBlock,
    thirdSliderBlock,
    fourthSliderBlock,
    fifthSliderBlock,
  ];

  const slidesNumber = steps.length - 2;

  const TRANSLATE_STEP = 300;
  const TOTAL_TRANSLATE_VALUE = slidesNumber * TRANSLATE_STEP;
  const MIN_X = -TOTAL_TRANSLATE_VALUE;
  const MAX_X = 0;
  const DURATION = .5;

  const onDragStart = () => {
    setArrowLeftActive(false);
    setArrowRightActive(false);
  };

  const onThrowComplete = () => {
    const transform = wrapperRef.current.style.transform;
    const fromEnd = 25;
    const length = transform.length - fromEnd;

    const xTranslateValue = parseInt(transform.substr(12, length));

    setXTranslate(xTranslateValue);

    if (xTranslateValue === MIN_X) {
      setArrowRightActive(false);
    } else {
      setArrowRightActive(true);
    }

    if (xTranslateValue === 0) {
      setArrowLeftActive(false);
    } else {
      setArrowLeftActive(true);
    }
  };

  useEffect(() => {
    gsap.registerPlugin(Draggable, InertiaPlugin);

    Draggable.create(wrapperRef.current, {
      type: 'x',
      bounds: {
        minX: MIN_X,
        maxX: 0,
      },
      inertia: true,
      onThrowComplete,
      onDragStart,
    });
  }, []);

  const txNext = () => {
    if (isArrowRightActive) {
      setArrowRightActive(false);
      setArrowLeftActive(false);

      const nextTranslateValue = xTranslate - TRANSLATE_STEP;
      let value;
      if (nextTranslateValue < MIN_X) {
        value = TOTAL_TRANSLATE_VALUE + xTranslate;
      } else {
        value = TRANSLATE_STEP;
      }

      tl.to(wrapperRef.current, { x: `-=${value}px`, ease: Power3.easeOut, duration: DURATION });

      setTimeout(() => {
        if (nextTranslateValue <= MIN_X) {
          setArrowRightActive(false);
          setArrowLeftActive(true);
        } else {
          setArrowRightActive(true);
          setArrowLeftActive(true);
        }
        setXTranslate(xValue => xValue - value);
      }, DURATION * 1000);
    }
  };

  const txBack = () => {
    if (isArrowLeftActive) {
      setArrowLeftActive(false);
      setArrowRightActive(false);

      const nextTranslateValue = xTranslate + TRANSLATE_STEP;
      let value;
      if (nextTranslateValue > MAX_X) {
        value = MAX_X - xTranslate;
      } else {
        value = TRANSLATE_STEP;
      }
      tl.to(wrapperRef.current, { x: `+=${value}px`, ease: Power3.easeOut, duration: DURATION });

      setTimeout(() => {
        if (nextTranslateValue >= MAX_X) {
          setArrowLeftActive(false);
          setArrowRightActive(true);
        } else {
          setArrowLeftActive(true);
          setArrowRightActive(true);
        }
        setXTranslate(xValue => xValue + value);
      }, DURATION * 1000);
    }
  };

  return (
    <div className={s.timeline}>
      <div className={s.timeline__wrapper} ref={wrapperRef}>
        {steps.map((step, i) => {
          return (
            <div className={s.timeline__step}
                 key={i}
            >
              <Step bigCircle={i === 0} text={step} />
            </div>
          );
        })}
      </div>

      <div onClick={() => txBack()}
           onMouseOver={() =>
             isArrowLeftActive ? onCursor('action') : ''
           }
           onMouseLeave={() => onCursor()}>

        <Arrow className={s(s.timeline__arrowSvgl, { active: isArrowLeftActive })} />
      </div>

      <div onClick={() => txNext()}
           onMouseOver={() =>
             isArrowRightActive ? onCursor('action') : ''
           }
           onMouseLeave={() => onCursor()}>

        <Arrow className={s(s.timeline__arrowSvgr, { active: isArrowRightActive })} />
      </div>
      
    </div>
  );
};
