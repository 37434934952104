import React from 'react';
import uuid from 'react-uuid';
import s from './bigText.scss';

export const BigText = ({content, colorAccent }) => {

  const returnSingleRow = (collection) => {
   return collection.map((singleRow) => {
      return (
        <div key={uuid()}> 
          {singleRow}
        </div>
      )
    })
  }; 
  
  return (
    <div className={s.bigText}>
      <p className={s(s.bigText__content, { colorAccent: colorAccent })}>
        { returnSingleRow(content) }
      </p>
    </div>
  );
};
