import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import s from './theBiggestText.scss';

export const TheBiggestText = () => {
  const intl = useIntl();

  return (
    <div className={s.theBiggestText}>
      <p className={s.theBiggestText__content}>
        { intl.formatMessage({id:'theBiggestTextPart1'}) }
      </p>
    </div>
  );
};
