import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { TweenLite, Power3, gsap, ScrollToPlugin } from 'gsap/all';
import CollectionsSlider from '../magnify-slider/collections-slider/CollectionsSlider';

import { BigText } from './bigText/BigText';
import { SmallText } from './smallText/SmallText';
import { Container } from '../container/Container';
import { TheBiggestText } from './theBiggestText/TheBiggestText';
import { Timeline } from '../timeline/Timeline';
import { useAppDispatchContext, useAppStateContext } from '../../context/appContext';
import ArrowIcon from '../../assets/svg/arrow.svg';

import s from './aboutUs.scss';


export const AboutUs = () => {
  const intl = useIntl();
  const dispatch = useAppDispatchContext();
  const { cursorStyles } = useAppStateContext();
  const onCursor = cursorType => {
    cursorType = (cursorStyles.includes(cursorType) && cursorType) || false;
    dispatch({ type: 'CURSOR_TYPE', cursorType: cursorType });
  };

  function gotoAnchor() {

    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const anchor = document.getElementById('anchor');
      const posTop = anchor && anchor.offsetTop;
      gsap.registerPlugin(ScrollToPlugin);

      TweenLite.to(window, 1, { scrollTo: { y: posTop }, ease: Power3.easeOut });
    }
  }

  const accentBigText = [
    intl.formatMessage({ id: 'accentBigTextPart1' }),
  ];

  const normalBigText = [
    intl.formatMessage({ id: 'normalBigText1' }),
  ];

  const firstTextBlock = {
    header: [
      intl.formatMessage({ id: 'firstTextBlockHeader1' }),
      intl.formatMessage({ id: 'firstTextBlockHeader2' }),
    ],
    content: intl.formatMessage({ id: 'firstTextBlockContent1' }) +
      intl.formatMessage({ id: 'firstTextBlockContent2' }) +
      intl.formatMessage({ id: 'firstTextBlockContent3' }) +
      intl.formatMessage({ id: 'firstTextBlockContent4' }),
  };

  const secondTextBlock = {
    header: [
      intl.formatMessage({ id: 'secondTextBlockHeader1' }),
      intl.formatMessage({ id: 'secondTextBlockHeader2' }),
    ],
    content: intl.formatMessage({ id: 'secondTextBlockContent1' }) +
      intl.formatMessage({ id: 'secondTextBlockContent2' }) +
      intl.formatMessage({ id: 'secondTextBlockContent3' }) +
      intl.formatMessage({ id: 'secondTextBlockContent4' }),
  };

  const thirdTextBlock = {
    header: [
      intl.formatMessage({ id: 'thirdTextBlockHeader1' }),
      intl.formatMessage({ id: 'thirdTextBlockHeader2' }),
    ],
    content: intl.formatMessage({ id: 'thirdTextBlockContent1' }) +
      intl.formatMessage({ id: 'thirdTextBlockContent2' }) +
      intl.formatMessage({ id: 'thirdTextBlockContent3' }),
  };

  const fourthTextBlock = {
    header: [
      intl.formatMessage({ id: 'fourthTextBlockHeader1' }),
      intl.formatMessage({ id: 'fourthTextBlockHeader2' }),
    ],
    content: intl.formatMessage({ id: 'fourthTextBlockContent1' }) +
      intl.formatMessage({ id: 'fourthTextBlockContent2' }) +
      intl.formatMessage({ id: 'fourthTextBlockContent3' }) +
      intl.formatMessage({ id: 'fourthTextBlockContent4' }),
  };

  const fifthTextBlock = {
    header: [
      intl.formatMessage({ id: 'fifthTextBlockHeader1' }),
      intl.formatMessage({ id: 'fifthTextBlockHeader2' }),
    ],
    content: intl.formatMessage({ id: 'fifthTextBlockContent1' }) +
      intl.formatMessage({ id: 'fifthTextBlockContent2' }) +
      intl.formatMessage({ id: 'fifthTextBlockContent3' }) +
      intl.formatMessage({ id: 'fifthTextBlockContent4' }),
  };


  const sixthTextBlock = {
    header: [
      intl.formatMessage({ id: 'sixthTextBlockHeader1' }),
      intl.formatMessage({ id: 'sixthTextBlockHeader2' }),
    ],
    content: intl.formatMessage({ id: 'sixthTextBlockContent1' }) +
      intl.formatMessage({ id: 'sixthTextBlockContent2' }) +
      intl.formatMessage({ id: 'sixthTextBlockContent3' }),
  };

  return (
    <div className={s.aboutUs}>
      <Container>
        <BigText content={accentBigText} colorAccent />
        <div className={s.aboutUs__arrow}
             onMouseOver={() => onCursor('action')}
             onMouseLeave={() => onCursor()}>
          <a onClick={gotoAnchor}>
            <ArrowIcon className={s.aboutUs__arrowSvg} />
          </a>
        </div>
        <SmallText header={firstTextBlock.header} content={firstTextBlock.content} />
      </Container>
      <div className={s.aboutUs__bluePart} id={'anchor'}>
        <Container>
          <BigText content={normalBigText} />
          <SmallText header={secondTextBlock.header} content={secondTextBlock.content} whiteAccent />
          <SmallText header={thirdTextBlock.header} content={thirdTextBlock.content} whiteAccent />
          <SmallText header={fourthTextBlock.header} content={fourthTextBlock.content} whiteAccent />
        </Container>
      </div>
      <Container>
        <TheBiggestText />
        <SmallText header={fifthTextBlock.header} content={fifthTextBlock.content} />
        <SmallText header={sixthTextBlock.header} content={sixthTextBlock.content} />
        <Timeline onCursor={onCursor} />
      </Container>
      <CollectionsSlider />
    </div>
  );
};
